@import url("https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap");

/********************************* UNIVERSAL STYLES **********************************/
:root {
    --it-teal: #19bdc7;
    --it-light-grey: #909394;
    --it-dark-grey: #3c4048;
}

/********************************* NAVBAR **********************************/

#navbar-section {
    background-color: white !important;
    border-bottom: 1px solid #f1f1f1;
}

#navbar-section .navbar-links .navbar-link {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: var(--it-light-grey);
}

#navbar-section .sign-up-button {
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    width: 100px;
}

#navbar-section .sign-up-button:hover {
    background-color: #13989f;
}

#navbar-section .log-in-button {
    background-color: transparent;
    border-color: var(--it-teal);
    color: var(--it-teal);
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    width: 100px;
}

#navbar-section .log-in-button:hover {
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
}

@media (max-width: 991px){
    #navbar-section .action-buttons {
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

@media (min-width: 992px){
    #navbar-section .action-buttons {
        margin-left: 8px;
    }
}

/********************************* BANNER **********************************/

@media (min-width: 992px) {
    #banner-section {
        margin-top: 150px;
        margin-bottom: 150px;
    }
}

@media (max-width: 991px) {
    #banner-section {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

#banner-section h1 {
    font-family: "Yeseva One", serif;
    font-size: 45px;
    color: var(--it-dark-grey) !important;
    line-height: 1.4 !important;
}

#banner-section p {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: var(--it-light-grey);
    line-height: 1.7 !important;
}

#banner-section .tutor-button {
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
}

#banner-section .tutor-button:hover {
    background-color: #13989f;
}

#banner-section .tutee-button {
    background-color: transparent;
    border-color: var(--it-teal);
    color: var(--it-teal);
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
}

#banner-section .tutee-button:hover {
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
}

/********************************* WHO WE ARE, WHAT WE DO **********************************/

@media (min-width: 992px) {
    #who-we-are-section,
    #what-we-do-section {
        margin-top: 150px;
        margin-bottom: 150px;
    }
}

@media (max-width: 991px) {
    #who-we-are-section,
    #what-we-do-section {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

#who-we-are-section h1,
#what-we-do-section h1 {
    font-family: "Yeseva One", serif;
    font-size: 35px;
    color: var(--it-dark-grey) !important;
    line-height: 1.4 !important;
}

#who-we-are-section p,
#what-we-do-section p {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: var(--it-light-grey);
    line-height: 1.7 !important;
}

/********************************* CALL TO ACTION **********************************/

@media (min-width: 992px) {
    #call-to-action-section {
        margin-top: 150px;
        margin-bottom: 180px;
    }
}

@media (max-width: 991px) {
    #call-to-action-section {
        margin-top: 50px;
        margin-bottom: 90px;
    }
}

#call-to-action-section h1 {
    font-family: "Yeseva One", serif;
    font-size: 40px;
    color: var(--it-dark-grey) !important;
    line-height: 1.4 !important;
}

#call-to-action-section p {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: var(--it-light-grey);
    line-height: 1.7 !important;
}

#call-to-action-section .tutor-button {
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

#call-to-action-section .tutee-button {
    background-color: transparent;
    border-color: var(--it-teal);
    color: var(--it-teal);
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

@media (min-width: 992px) {
    #call-to-action-section .call-to-action {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 992px) {
    #call-to-action-section .tutor-section {
        border-right: 1px solid #dfdfdf;
    }
}

#call-to-action-section .horizontal-divider {
    margin-top: 80px;
    margin-bottom: 80px;
    background-color: #dfdfdf;
}

#call-to-action-section .tutor-button:hover {
    background-color: #13989f;
}

#call-to-action-section .tutee-button:hover {
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
}

/********************************* FOOTER **********************************/
#footer-section {
    background-color: var(--it-dark-grey);
    color: white;
    position: relative;
    width: 100%;
}

#footer-section .footer-text {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    margin-bottom: 0;
    padding-left: 15px;
}

@media (max-width: 575px) {
    #footer-section .footer-text {
        font-size: 14px;
    }

    #footer-section .footer-logo {
        width: 40px;
    }
}

#footer-section .social-icon-list {
    margin-bottom: 0;
}

#footer-section .social-icon {
    color: white;
    font-size: 22px;
    margin-right: 10px;
}

#footer-section .social-icon:hover {
    color: var(--it-teal);
}

/********************************* FORMS (LOGIN, SIGNUP, APPLICATION) **********************************/

#form-container {
    background-color: white;
    padding: 50px;
    margin: 100px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 0px) {
    #form-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 768px) {
    #form-container {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 992px) {
    #form-container {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}

/* heading and description */
#form-container .form-heading {
    font-family: "Yeseva One", serif;
    font-size: 30px;
    color: var(--it-dark-grey);
}

#form-container .form-description {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-light-grey);
    font-weight: 300;
}

#form-container .form-link {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-light-grey);
    font-weight: 300;
    color: var(--it-teal);
}

/* text boxes */
#form-container .form-label {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-dark-grey);
    font-weight: 600;
    margin-bottom: 5px;
}

#form-container .form-control::placeholder {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #b8b8b8;
    font-weight: 300;
}

#form-container .input-text {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-dark-grey);
    font-weight: 400;
}

#form-container .form-control:focus {
    border-color: #dee2e6;
    box-shadow: none;
}

/* radio buttons */
#form-container .form-check-label {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-dark-grey);
    font-weight: 400;
}

#form-container .form-check-input:focus {
    background-color: var(--it-teal);
    border-color: var(--it-teal);
    box-shadow: 0 0 0 0.25rem rgba(25, 189, 199, 0.25);
}

#form-container .form-check-input:checked {
    background-color: var(--it-teal);
    border-color: var(--it-teal);
}

/* submit button */
#form-container .form-submit-button {
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    margin-top: 15px;
}

#form-container .invalid-login-message {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: rgb(202, 20, 20);
}

/* dropdown */
#form-container .form-select.dropdown-placeholder {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #b8b8b8;
    font-weight: 300;
}

#form-container .form-select.dropdown-option {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-dark-grey);
    font-weight: 300;
}

/* multiselect */
#form-container .input-description {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: var(--it-light-grey);
    font-weight: 300;
    margin-bottom: 5px;
}

/* override default form focus styles */
#form-container .form-select:focus {
    border-color: #dee2e6;
    outline: none;
    box-shadow: none;
}

/* file upload */
#form-container .file-upload-box {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-dark-grey);
}

#form-container .message-heading {
    margin-top: 25px;
    font-family: "Yeseva One", serif;
    font-size: 26px;
    color: var(--it-dark-grey);
    text-align: center;
}

#form-container .message-description {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: var(--it-light-grey);
    font-weight: 300;
    text-align: center;
}

/********************************* DASHBOARD SIDEBAR **********************************/

.dashboard-container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.dashboard-content{
    flex-grow: 1;
}

#dashboard-sidebar {
    background-color: white;
}

/* sidebar width */
@media (min-width: 1400px) {
    #dashboard-sidebar .sidebar-logo {
        width: 300px;
    }
}

@media (max-width: 1399px) {
    #dashboard-sidebar .sidebar-logo {
        width: 250px;
    }
}

@media (max-width: 1199px) {
    #dashboard-sidebar .sidebar-logo {
        width: 230px;
    }
}

/* sidebar logo padding and margin */
@media (min-width: 1200px) {
    #dashboard-sidebar .sidebar-logo {
        margin: 20px 10px;
        padding: 25px 30px;
    }
}

@media (max-width: 1199px) {
    #dashboard-sidebar .sidebar-logo {
        padding: 15px 20px;
    }
}

#dashboard-sidebar .sidebar-link {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #909394;
    margin: 3px 10px;
    padding: 25px 30px;
}

@media (min-width: 1400px) {
    #dashboard-sidebar .sidebar-link {
        font-size: 16px;
    }
}

@media (max-width: 1399px) {
    #dashboard-sidebar .sidebar-link {
        font-size: 14px;
    }
}

#dashboard-sidebar .sidebar-link:hover,
#dashboard-sidebar .sidebar-link.active {
    background-color: #e7fafb;
    border-radius: 10px;
    color: var(--it-teal);
}

#dashboard-sidebar .sidebar-icon {
    padding-right: 25px;
}

/********************************* MAIN CONTENT **********************************/

#dashboard-main-content {
    background-color: #f4f4f4;
}

/* main content padding */
@media (min-width: 1400px) {
    #dashboard-main-content {
        padding: 70px 150px;
    }
}

@media (max-width: 1399px) {
    #dashboard-main-content {
        padding: 70px 80px;
    }
}

@media (max-width: 767px) {
    #dashboard-main-content {
        padding: 70px 10px;
    }
}

/********************************* PROFILE PAGE **********************************/

.table-container {
    background-color: white;
    border-radius: 10px;
    padding: 0px 20px !important;
}

.dashboard-header {
    font-family: "Yeseva One", serif;
    font-size: 35px;
    color: var(--it-dark-grey);
    margin-bottom: 30px;
    margin-right: auto;
}

.profile-table {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin-bottom: 50px !important;
}

.profile-table tbody tr:last-child td {
    border-bottom: none;
    padding-bottom: 30px !important;
}

.profile-table .profile-table-title {
    font-family: "Yeseva One", serif;
    color: var(--it-dark-grey);
    font-size: 24px;
    padding: 30px 10px !important;
}

.profile-table .profile-table-header {
    color: var(--it-dark-grey);
    padding: 20px 10px !important;
}

.profile-table .profile-table-text {
    color: var(--it-light-grey);
    padding: 20px 10px !important;
}



/********************************* UPCOMING SESSIONS PAGE **********************************/
.upcoming-session-card {
    background-color: white;
    border-radius: 10px;
    padding: 50px !important;
    margin: 12px;
}

.upcoming-session-card .session-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
}

/* mobile view */
@media (max-width: 767px) {
    .upcoming-session-card .session-date {
        align-items: start;
        border-bottom: 1px solid rgb(211, 211, 211);
        padding: 0 0 40px 0;
    }
}

/* desktop view */
@media (min-width: 768px) {
    .upcoming-session-card .session-date {
        align-items: center;
        border-right: 1px solid rgb(211, 211, 211);
    }
}

.upcoming-session-card .session-date .session-month {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    color: var(--it-light-grey);
    margin: 0;
}

.upcoming-session-card .session-date .session-day {
    font-family: "Yeseva One", serif;
    font-size: 65px;
    color: var(--it-dark-grey);
    margin: 0;
}

.upcoming-session-card .session-date .session-time {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--it-light-grey);
    margin: 0;
}

.upcoming-session-card .session-information {
    padding: 5px 40px;
}

/* mobile view */
@media (max-width: 767px) {
    .upcoming-session-card .session-information {
        padding: 40px 0 0 0;
    }
}

/* desktop view */
@media (min-width: 768px) {
    .upcoming-session-card .session-information {
    }
}

.upcoming-session-card .session-information-title {
    font-family: "Yeseva One", serif;
    font-size: 24px;
    color: var(--it-dark-grey);
    margin-top: 10px;
    margin-bottom: 10px;
}

.upcoming-session-card .session-information-text {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-light-grey);
    margin-top: 15px;
    margin-bottom: 30px;
}

.upcoming-session-card .session-information-text > div {
    margin-bottom: 12px !important;
}

.upcoming-session-card .session-information-text div span,
.upcoming-session-card .session-information-text div div {
    color: var(--it-dark-grey);
    font-weight: 600;
}

.upcoming-session-card .join-meeting-button {
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    width: 100%;
}

/* mobile view */
@media (max-width: 767px) {
    .upcoming-session-card .join-meeting-button {
        margin-top: 20px;
    }
}

/* desktop view */
@media (min-width: 768px) {
    .upcoming-session-card .join-meeting-button {
        margin-top: 30px;
    }
}

.upcoming-session-card .join-meeting-button:hover,
.upcoming-session-card .join-meeting-button:active {
    background-color: #13989f !important;
    border-color: transparent !important;
}

.upcoming-session-card .lesson-plan-button,
.upcoming-session-card .session-notes-button {
    background-color: white;
    border: 1px solid var(--it-teal);
    color: var(--it-teal);
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    width: 250px;
    margin-top: 5px;
}

.upcoming-session-card .lesson-plan-button:hover,
.upcoming-session-card .lesson-plan-button:active,
.upcoming-session-card .session-notes-button:hover,
.upcoming-session-card .session-notes-button:active {
    background-color: var(--it-teal) !important;
    color: white;
    border-color: transparent !important;
}

/********************************* EDITOR **********************************/
.editor-heading{
    font-family: "Yeseva One", serif;
    font-size: 35px;
    color: var(--it-dark-grey);
    margin-bottom: 30px;
}

.editor-details{
    margin-bottom: 20px;
}

.editor-date, .editor-person{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: var(--it-dark-grey);
    margin-bottom: 5px;
}

/* entire editor */
.editor{
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(194, 194, 194);
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

/* menu bar */
.editor-menu-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editor-menu-bar button{
    font-size: 18px;
    margin: 10px;
    border: none;
    outline: none;
    background: none;
}

.editor-menu-bar button.is-active{
    background-color: #d8d8d8;
    border-radius: 5px;
}

/* text editor */
.tiptap{
    background-color: white;
    padding: 20px;
    border-top: 1px solid rgb(194, 194, 194);
    border-radius: 0 0 5px 5px;
    min-height: 200px;
}

/* tiptap default styles */
.tiptap > * + * {
    margin-top: 0.75em;
}

.tiptap ul,
.tiptap ol {
    padding: 0 1rem;
}

.tiptap h1,
.tiptap h2,
.tiptap h3 {
    line-height: 1.1;
}

.tiptap img {
    max-width: 100%;
    height: auto;
}

/* save button */
.editor-save-button {
    background-color: var(--it-teal) !important;
    border-color: transparent !important;
    color: white;
    font-family: "Open Sans", sans-serif !important;
    font-size: 14px !important;
    margin-top: 20px;
    margin-right: 10px;
    width: 100px;
}

.editor-save-button:hover,
.editor-save-button:active {
    background-color: #13989f !important;
    border-color: transparent !important;
}

/* back button */
.editor-back-button {
    background-color: transparent !important;
    border-color: var(--it-teal) !important;
    color: var(--it-teal) !important;
    font-family: "Open Sans", sans-serif !important;
    font-size: 14px !important;
    margin-top: 20px;
    width: 100px;
}

.editor-back-button:hover,
.editor-back-button:active {
    background-color: var(--it-teal) !important;
    color: white !important;
    border-color: transparent !important;
}

/********************************* MY TUTEES PAGE **********************************/

.commitment-card {
    background-color: white;
    border-radius: 10px;
    padding: 30px 50px !important;
    margin: 12px;
}

.commitment-card .commitment-name {
    font-family: "Yeseva One", serif;
    font-size: 24px;
    color: var(--it-dark-grey);
    margin-top: 10px;
    margin-bottom: 10px;
}

.commitment-card .commitment-information {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-light-grey);
    margin-top: 15px;
    margin-bottom: 30px;
}

.commitment-card .commitment-information p {
    margin-bottom: 10px !important;
}

.commitment-card .commitment-information p span {
    color: var(--it-dark-grey);
    font-weight: 600;
}

.commitment-card .tutoring-history-button {
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    width: 100%;
}

.commitment-card .tutoring-history-button:hover,
.commitment-card .tutoring-history-button:active {
    background-color: #13989f !important;
    border-color: transparent !important;
}

/********************************* TUTORING HISTORY **********************************/

.tutoring-history-title {
    font-family: "Yeseva One", serif;
    color: var(--it-dark-grey);
    font-size: 24px;
    padding: 30px 10px 10px 10px !important;
    margin-bottom: 0 !important;
}

.tutoring-history-table {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin-bottom: 50px !important;
}

.tutoring-history-table tbody tr:last-child td {
    border-bottom: none;
    padding-bottom: 30px !important;
}

.tutoring-history-table .tutoring-history-header {
    color: var(--it-dark-grey);
    padding: 20px 10px !important;
}

.tutoring-history-table .tutoring-history-text {
    color: var(--it-light-grey);
    padding: 20px 10px !important;
}

.tutoring-history-link{
    color: var(--it-teal);
}

.tutoring-history-link:hover{
    color: #13989f;
}

/********************************* RESOURCES PAGE **********************************/
.resource-section:not(:last-of-type){
    margin-bottom: 70px;
}

.resources-header{
    font-family: "Yeseva One", serif;
    font-size: 24px;
    color: var(--it-dark-grey);
    margin-bottom: 20px;
    margin-right: auto;
}

.resource-card{
    background-color: white;
    border-radius: 10px;
    border: none !important;
}

.resource-card .resource-card-image{
    width: 100%; 
    height: 200px; 
    object-fit: cover;
}

.resource-card .resource-card-body{
    padding: 20px;
}

.resource-card .resource-card-title{
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: var(--it-dark-grey);
    margin-top: 5px;
    margin-bottom: 10px;
}

.resource-card .resource-card-text{
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--it-light-grey);
    margin-bottom: 25px;
}

.resource-card .resource-card-button{
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    width: 100%;
    margin-bottom: 10px;
}

.resource-card .resource-card-button:hover {
    background-color: #13989f;
    border-color: transparent;
}

/********************************* VOLUNTEER HOURS PAGE **********************************/
.dashboard-form-container {
    background-color: white;
    border-radius: 10px;
    padding: 50px !important;
    margin-bottom: 50px !important;
}

@media (min-width: 0px) {
    .dashboard-form-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 768px) {
    .dashboard-form-container {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 992px) {
    .dashboard-form-container {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}

/* heading and description */
.dashboard-form-container .form-heading {
    font-family: "Yeseva One", serif;
    font-size: 24px;
    color: var(--it-dark-grey);
}

.dashboard-form-container .form-description {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-light-grey);
    font-weight: 300;
}

.dashboard-form-container .form-link {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-light-grey);
    font-weight: 300;
    color: var(--it-teal);
}

/* text boxes */
.dashboard-form-container .form-label {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-dark-grey);
    font-weight: 600;
    margin-bottom: 5px;
}

.dashboard-form-container .form-control::placeholder {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #b8b8b8;
    font-weight: 300;
}

.dashboard-form-container .input-text {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-dark-grey);
    font-weight: 400;
}

.dashboard-form-container .form-control:focus {
    border-color: #dee2e6;
    box-shadow: none;
}

/* radio buttons */
.dashboard-form-container .form-check-label {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-dark-grey);
    font-weight: 400;
}

.dashboard-form-container .form-check-input:focus {
    background-color: var(--it-teal);
    border-color: var(--it-teal);
    box-shadow: 0 0 0 0.25rem rgba(25, 189, 199, 0.25);
}

.dashboard-form-container .form-check-input:checked {
    background-color: var(--it-teal);
    border-color: var(--it-teal);
}

/* submit button */
.dashboard-form-container .form-submit-button {
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    margin-top: 15px;
}

.dashboard-form-container .form-submit-button:hover,
.dashboard-form-container .form-submit-button:active {
    background-color: #13989f !important;
    border-color: transparent !important;
}

.dashboard-form-container .invalid-login-message {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: rgb(202, 20, 20);
}

/* dropdown */
.dashboard-form-container .form-select.dropdown-placeholder {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #b8b8b8;
    font-weight: 300;
}

.dashboard-form-container .form-select.dropdown-option {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-dark-grey);
    font-weight: 300;
}

/* multiselect */
.dashboard-form-container .input-description {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: var(--it-light-grey);
    font-weight: 300;
    margin-bottom: 5px;
}

/* override default form focus styles */
.dashboard-form-container .form-select:focus {
    border-color: #dee2e6;
    outline: none;
    box-shadow: none;
}

/* file upload */
.dashboard-form-container .file-upload-box {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-dark-grey);
}

.table-container {
    background-color: white;
    border-radius: 10px;
    padding: 0px 20px !important;
}

.past-hours-requests-title {
    font-family: "Yeseva One", serif;
    color: var(--it-dark-grey);
    font-size: 24px;
    padding: 30px 10px 10px 10px !important;
    margin-bottom: 0 !important;
}

.past-hours-requests-table {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin-bottom: 50px !important;
}

.past-hours-requests-table tbody tr:last-child td {
    border-bottom: none;
    padding-bottom: 30px !important;
}

.past-hours-requests-table .past-hours-requests-header {
    color: var(--it-dark-grey);
    padding: 20px 10px !important;
}

.past-hours-requests-table .past-hours-requests-text {
    color: var(--it-light-grey);
    padding: 20px 10px !important;
}

/********************************* TUTORS/TUTEES **********************************/

.admin-table {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin-bottom: 50px !important;
}

.admin-table tbody tr:last-child td {
    border-bottom: none;
    padding-bottom: 30px !important;
}

.admin-table .admin-table-header {
    color: var(--it-dark-grey);
    padding: 20px 10px !important;
}

.admin-table .admin-table-text {
    color: var(--it-light-grey);
    padding: 20px 10px !important;
}

.admin-table-link{
    color: var(--it-teal);
}

.admin-table-link:hover{
    color: #13989f;
}


/********************************* TUTOR/TUTEE INFORMATION PAGE **********************************/

.tutor-information-table, .tutee-information-table {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin-bottom: 50px !important;
}

.tutor-information-table tbody tr:last-child td, .tutee-information-table tbody tr:last-child td {
    border-bottom: none;
    padding-bottom: 30px !important;
}

.tutor-information-table .tutor-information-table-title, .tutee-information-table .tutee-information-table-title {
    font-family: "Yeseva One", serif;
    color: var(--it-dark-grey);
    font-size: 24px;
    padding: 30px 10px !important;
}

.tutor-information-table .tutor-information-table-header, .tutee-information-table .tutee-information-table-header {
    color: var(--it-dark-grey);
    padding: 20px 10px !important;
}

.tutor-information-table .tutor-information-table-text, .tutee-information-table .tutee-information-table-text {
    color: var(--it-light-grey);
    padding: 20px 10px !important;
}


/********************************* APPROVE VOLUNTEER HOURS PAGE **********************************/

.hours-approval-title {
    font-family: "Yeseva One", serif;
    font-size: 24px;
    color: var(--it-dark-grey);
    margin-bottom: 10px;
}

.hours-approval-text {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--it-light-grey);
    margin-top: 15px;
}

.hours-approval-text > div {
    margin-bottom: 12px !important;
}

.hours-approval-text div span {
    color: var(--it-dark-grey);
    font-weight: 600;
}

.dashboard-form-container .download-form-button {
    background-color: transparent !important;
    border-color: var(--it-teal) !important;
    color: var(--it-teal) !important;
    font-family: "Open Sans", sans-serif !important;
    font-size: 14px !important;
    margin-top: 10px;
    width: 100px;
}

.dashboard-form-container .download-form-button:hover,
.dashboard-form-container .download-form-button:active {
    background-color: var(--it-teal) !important;
    color: white !important;
    border-color: transparent !important;
}

.dashboard-form-container .submit-button {
    background-color: var(--it-teal);
    border-color: transparent;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    margin-top: 15px;
}

.dashboard-form-container .submit-button:hover,
.dashboard-form-container .submit-button:active {
    background-color: #13989f !important;
    border-color: transparent !important;
}
